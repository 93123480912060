import { useTranslation } from 'react-i18next';

import { createMarkup } from '../utils';

export default function Disclaimers() {
  const { t } = useTranslation();

  return (
    <div className="disclaimer mt-4 text-left">
      <p dangerouslySetInnerHTML={createMarkup(t('PhotosAreIllustrative'))} />
      <p dangerouslySetInnerHTML={createMarkup(t('DisclaimerReservation'))} />
      <strong dangerouslySetInnerHTML={createMarkup(t('LeasingInfoTitle'))} />
      <p dangerouslySetInnerHTML={createMarkup(t('LeasingInfoText'))} />
    </div>
  );
}
